import React from "react"

import content from '../../content/subscribe.yaml'

export default class Subscribe extends React.Component {

  render() {
    return(
  <section id="subscribe">
    <div className="row section-head">
      <div className="twelve columns">
        <h1>{ content.title }</h1>
        <p>{ content.body }</p>
    </div>
  </div>

  <form name="contact" method="POST" data-netlify="true">
    <p>
      <label>Your Name: <input type="text" name="name" /></label>
      <label>Your Email: <input type="email" name="email" /></label>
    </p>
    <p>
      <label>Your Message: <textarea type="text" name="message" /></label>
    </p>
    <p>
      <input type="hidden" name="form-name" value="contact" />
      <button type="submit">Send</button>
    </p>
  </form>

  </section>
    )
  }
}
