import Layout from "../components/layout"
import Subscribe from "../components/subscribe"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useSiteMetadata } from "../hooks/use-site-metadata"

import NavMenu from "../components/navmenu"
import HeaderSocial from "../components/header-social"
import { navigate } from "gatsby"
import React, { useState } from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import Scrollspy from "react-scrollspy"

import content from "../../content/faq.yml"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

export default () => (
  <>
    <header>
      <div className="logo">
         <AnchorLink to="/#top">
           <img alt="" src={ useSiteMetadata().logo } />
         </AnchorLink>
      </div>
    <FaqHeader />
      <HeaderSocial />

    </header>
    <Layout>

        {/* Style Guide Section
        ================================================== */}
        <section id="styles"
          style= {{
            padding: '90px 0 72px',
            background: '#fff'
          }}
        >
          <div className="row section-head">
            <h1>Frequently Asked Questions</h1>
          </div> {/* Row End*/}
           <div className="row add-bottom">

              <div className="add-bottom">
                {(content.map((value) =>
                <>
                 <h4>{value.q}</h4>
                 <p>{value.a}</p>
                 </>
                ))}
                 </div></div>
                 </section>
                 <Subscribe />


    </Layout>
  </>
)

function FaqHeader() {
  const [isVisible, setVisibility] = useState(false)

  const { menuItems } = useSiteMetadata()


  let showStyle = null
  if (isVisible){
    showStyle = {transform: 'scaleY(1)'}
  }else{
    showStyle = null
  }

  return(
    <nav id="nav-wrap">
      <button
        id="toggle-btn"
        href="/#"
        title="Menu"
        onClick={() => setVisibility(!isVisible)}
        >
        <FontAwesomeIcon icon={ faBars } />
      </button>

      <Scrollspy
        className="nav mobile"
        style={showStyle}
        items={ menuItems.map(a => a.path) }
        currentClassName="current"
        offset={-100} >

        <li key="home"><button onClick={() => {
          navigate("/")
        }}>Home</button></li>

      </Scrollspy>
    </nav>
  )
}